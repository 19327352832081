<template>
  <v-container>
      <h1 class="text-center mt-4 mb-4" style="font-family:Life Savers">Derniers commentaires</h1>
      <v-list elevation="3">
          <v-list-item v-for="comment in comments" :key="comment.text" @click="()=>seeComment(comment)">
              <v-list-item-content>
                  <v-list-item-title>
                      {{comment.author}} - {{comment.date ? dateHeure(new Date(comment.date.seconds*1000)): ""}} <v-btn class="ml-4" icon @click.stop="()=>deleteComment(comment)"><v-icon>delete</v-icon></v-btn>
                  </v-list-item-title>
                  <v-list-item-subtitle>{{comment.place_name}} pour le {{dateFrance(new Date(comment.day_date.seconds*1000))}}</v-list-item-subtitle>
              </v-list-item-content>
          </v-list-item>
      </v-list>
  </v-container>
</template>

<script>
import {firestore} from '../main'
import {doc, getDoc, updateDoc, arrayRemove} from 'firebase/firestore' 
import {dateHeure, dateFrance} from '../classes'
export default {
    data() {
        return {
            comments:[]
        }
    },
    methods:{
        dateHeure(date) {return dateHeure(date)},
        dateFrance(date) {return dateFrance(date)},
        async deleteComment(comment) {
            await updateDoc(doc(firestore,"stats/recent_actions"),{comments:arrayRemove(comment)})
            this.comments.splice(this.comments.indexOf(comment),1);
        },
        async seeComment(comment) {
            await this.deleteComment(comment);
            this.$router.push(`/continent/${comment.continent_id}/pays/${comment.country_id}/lieu/${comment.place_id}`)
        }
    },
    async created() {
        this.comments = (await getDoc(doc(firestore,"stats/recent_actions"))).data().comments;
    }
}
</script>

<style>

</style>