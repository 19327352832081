<template>
  <v-container>
      <h1 class="text-center mt-4 mb-4" style="font-family:Life Savers">Derniers visiteurs</h1>
      <v-list elevation="3">
          <v-list-item v-for="visitor in visitors" :key="visitor.name">
            <v-list-item-title>{{visitor.name}} - {{dateHeure(new Date(visitor.date.seconds*1000))}}</v-list-item-title>
          </v-list-item>
      </v-list>
  </v-container>
</template>

<script>
import {firestore} from '../main'
import {doc, getDoc} from 'firebase/firestore';
import {dateHeure} from '../classes'
export default {
    data() {
        return {
            visitors:[]
        }
    },
    methods:{
        dateHeure(date) {return dateHeure(date)}
    },
    async created(){
        let visitors = (await getDoc(doc(firestore,"stats","recent_actions"))).data();
        delete visitors.comments;
        this.visitors = Object.values(visitors);
        this.visitors = this.visitors.sort((a,b)=>b.date.seconds - a.date.seconds);
    }
}
</script>

<style>

</style>